import { OutlineCta } from '@americastestkitchen/mise/components/CTAs/OutlineCta/index';
import styles from './contentJumpLinks.module.scss';
import { MutableRefObject, MouseEvent } from 'react';

interface ContentLinks {
  jumpLinkAnchor: string;
  linkText: string;
  refElement: MutableRefObject<null | HTMLDivElement>;
}

interface ContentJumpLinks {
  contentLinks?: ContentLinks[];
}

const ContentJumpLinks: React.FC<ContentJumpLinks> = ({ contentLinks }) => {
  function scrollToElement(
    e: MouseEvent<HTMLAnchorElement>,
    element: MutableRefObject<null | HTMLDivElement>,
  ) {
    e.preventDefault();
    if (element) {
      element?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  return (
    <div>
      {contentLinks && (
        <div className={styles.contentWrapper}>
          {contentLinks.map((link, index) => {
            const { linkText, jumpLinkAnchor, refElement } = link;
            const mixpanelData = {
              link_name: linkText,
              destination_URL: jumpLinkAnchor,
            };
            return (
              <OutlineCta
                as="a"
                href={`#${jumpLinkAnchor}`}
                key={index}
                className={styles.jumpLink}
                data-mx={JSON.stringify(mixpanelData)}
                onClick={(e) => {
                  scrollToElement(e, refElement);
                }}
              >
                {linkText}
              </OutlineCta>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ContentJumpLinks;
