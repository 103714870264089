import { useEffect, useMemo, useRef } from 'react';
import styles from './ccoHomePage.module.scss';
import ccoHomePageData from './data/ccoHomePageData';
import analytics from 'lib/analytics';

import useMarketingHat from 'lib/hooks/useMarketingHat';
import usePiano from 'components/integrations/PianoPlacements/hooks/usePiano';
import { linkSelectionHelper } from '../atk_homepage/linkSelectionHelper';

import { brandLandingAdsPresentedEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/AdsPresented';
import { brandLandingFreeTrialEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/FreeTrial';
import { brandLandingNewsletterEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/EmailNewsletter';

import BrandHero from './../../components/brandpages/Hero/BrandHero';
import BrandImageGrid from './../../components/brandpages/ImageGrid/BrandImageGrid';
import FullWidthContentBlock from 'components/brandpages/FullWidthContentBlock/FullWidthContentBlock';
import FullWidthHeadline from 'components/brandpages/FullWidthHeadline/FullWidthHeadline';
import FullWidthImageCarousel from 'components/brandpages/FullWidthImageCarousel/FullWidthImageCarousel';
import FullWidthVideo from 'components/brandpages/FullWidthVideo/FullWidthVideo';
import StandardDocCardCarousel from 'components/brandpages/StandardDocCardCarousel/StandardDocCardCarousel';
import TeamMemberCarousel from 'components/brandpages/TeamMemberCarousel/TeamMemberCarousel';
import Testimonials from './../../components/brandpages/Testimonials/Testimonials';
import ToggleProcessGrid from 'components/brandpages/ToggleProcessGrid/ToggleProcessGrid';
import TwoColumnText from 'components/brandpages/TwoColumnText/TwoColumnText';
import TwoUpDocCardCarousel from 'components/brandpages/TwoUpDocCardCarousel/TwoUpDocCardCarousel';
import ContentJumpLinks from 'components/brandpages/ContentJumpLinks/ContentJumpLinks';

const CCOHomePage = () => {
  const {
    fullWidthContentBlocks: { fwbCoastToCoast, fwcbBakedInTheOven },
    fullWidthHeadlines: { fwhWatchAndCook },
    fullWidthCarousel: { fwcImages },
    fullWidthVideo: { fwvWatchAndCook, fwvOnTheRoad, fwvToniTipton },
    twoColumnTexts: {
      tctMagazineCarousel,
      tctEpisodeDetails,
      tctOnTheRoad,
      tctTasteOfCooks,
      tctPortraits,
      tctToniTipton,
    },
    teamMemberCarouselItems,
    brandImageGrids: { bigCoastToCoast, bigBakedInTheOven },
    brandHeroes: { bhRecipeYouLove, bhResearch, bhAmericasFavorite },
    regionalRecipesCarousel,
    mostPopularCarousel,
    testimonials,
    twoUpCarousels: { tucStoryOfFood },
    ProcessGrid,
  } = useMemo(() => ccoHomePageData, []);

  const { segment } = useMarketingHat();
  const shouldInit = useRef(false);
  const onTheRoadRef = useRef<null | HTMLDivElement>(null);
  const magazineRef = useRef<null | HTMLDivElement>(null);
  const tvShowRef = useRef<null | HTMLDivElement>(null);

  const initPiano = usePiano({
    eventHandlers: [
      (e: any) =>
        brandLandingNewsletterEvent({
          event: e,
          location: 'brand_page_cco',
        }),
      (e: any) =>
        brandLandingFreeTrialEvent({
          event: e,
          location: 'brand_page_cco',
        }),
    ],
    postHandlers: !!shouldInit.current,
    segment,
  });

  useEffect(() => {
    if (shouldInit.current) return;
    brandLandingAdsPresentedEvent({
      location: 'brand_page_cco',
    });
    shouldInit.current = true;
  }, [initPiano]);

  useEffect(() => {
    analytics.track(
      'PAGEVIEW',
      { location: 'brand_page_cco' },
      { transport: 'sendBeacon' },
    );
  }, []);
  const jumpLinks = [
    {
      jumpLinkAnchor: 'magazine-anchor',
      linkText: 'Magazine',
      refElement: magazineRef,
    },
    {
      jumpLinkAnchor: 'tv-show-anchor',
      linkText: 'TV Show',
      refElement: tvShowRef,
    },
    {
      jumpLinkAnchor: 'on-the-road-anchor',
      linkText: 'On The Road',
      refElement: onTheRoadRef,
    },
  ];
  const trackPageClick = linkSelectionHelper();

  return (
    <div onClick={trackPageClick} className={styles.ccoBrandPage}>
      <section aria-label="The Recipes You Love and the Stories Behind Them">
        <div className={styles.ccoBrandHero}>
          <BrandHero
            altText={bhRecipeYouLove.altText}
            cloudinaryIds={bhRecipeYouLove.cloudinaryIds}
          ></BrandHero>
        </div>
      </section>
      <section
        aria-label="Jump to links"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.ccoContentLinks}>
          <ContentJumpLinks contentLinks={jumpLinks} />
        </div>
      </section>

      <section
        aria-label="From Coast to Coast"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.coastToCoastFWContentBlock}>
          <FullWidthContentBlock
            header={fwbCoastToCoast.header}
            content={fwbCoastToCoast.content}
          />
        </div>
        <div className={styles.coastToCoastImageGrid}>
          <BrandImageGrid
            image1={bigCoastToCoast.fullPortrait}
            image2={bigCoastToCoast.fullLandscape}
            image3={bigCoastToCoast.square1}
            image4={bigCoastToCoast.square2}
          />
        </div>
        <div className={styles.freeTrialMarketingAd}>
          <div className="cco-newsletter" />
        </div>
      </section>

      <section
        ref={tvShowRef}
        id="tv-show-anchor"
        aria-label="Watch and Cook With Us"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.watchAndCookHeader}>
          <FullWidthHeadline header={fwhWatchAndCook.header} />
        </div>
        <div className={styles.watchAndCookVideo}>
          <FullWidthVideo
            videoTitle="cctv_1"
            source={fwvWatchAndCook.zypeIdSource}
          />
        </div>
        <div className={styles.watchAndCookContent}>
          <TwoColumnText {...tctEpisodeDetails} />
        </div>
      </section>

      <section
        aria-label="Cook's Country Magazines"
        className={`${styles.setWidthContentBlock} ${styles.roseBorderTop}`}
      >
        <div
          ref={magazineRef}
          className={styles.fullWidthImageCarouselWrapper}
          id="magazine-anchor"
        >
          <FullWidthImageCarousel images={fwcImages} />
        </div>
        <div className={styles.cooksCountryMagazineTextBlock}>
          <TwoColumnText {...tctMagazineCarousel} />
        </div>

        <div className={styles.freeTrialMarketingAd2}>
          <div className="cco-free-trial" />
        </div>
      </section>

      <section
        aria-label="Regional Favorites"
        className={`${styles.setWidthContentBlock} ${styles.roseBorderTop}`}
      >
        <div className={styles.tasteOfCooksTextBlock}>
          <TwoColumnText {...tctTasteOfCooks} />
        </div>
        <div className={styles.popularRecipesCarouselWrapper}>
          <StandardDocCardCarousel
            carouselCards={mostPopularCarousel}
            carouselTitle="Popular Recipes"
          />
        </div>
        <div className={styles.newFavoritesCarouselWrapper}>
          <StandardDocCardCarousel
            carouselCards={regionalRecipesCarousel}
            carouselTitle="Regional Favorites"
          />
        </div>
      </section>

      <section aria-label="Research that uncovers the historic roots of American recipes">
        <div className={styles.historicRootsBrandHero}>
          <BrandHero
            altText={bhResearch.altText}
            cloudinaryIds={bhResearch.cloudinaryIds}
          ></BrandHero>
        </div>
        <div
          className={`${styles.setWidthContentBlock} ${styles.historicRootsArticlesCarousel}`}
        >
          <TwoUpDocCardCarousel
            carouselTitle="Stories of Food in America"
            carouselCards={tucStoryOfFood}
          />
        </div>
      </section>

      <section
        ref={onTheRoadRef}
        aria-label="On The Road"
        id="on-the-road-anchor"
        className={`${styles.setWidthContentBlock} ${styles.roseBorderTop}`}
      >
        <div className={styles.onTheRoadFWVideo}>
          <FullWidthVideo
            videoTitle="on_the_road"
            source={fwvOnTheRoad.zypeIdSource}
          />
        </div>
        <div className={styles.onTheRoadTextBlock}>
          <TwoColumnText {...tctOnTheRoad} />
        </div>
      </section>

      <section
        aria-label="Portraits of the People Who Feed Us"
        className={`${styles.setWidthContentBlock} ${styles.roseBorderTop}`}
      >
        <div className={styles.portraitsTextBlock}>
          <TwoColumnText {...tctPortraits} />
        </div>
        <div className={styles.portraitsGrid}>
          <ToggleProcessGrid processGrid={ProcessGrid} />
        </div>
      </section>

      <section
        aria-label="In the Library with Toni Tipton-Martin"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.toniTiptonFWVideo}>
          <FullWidthVideo
            videoTitle="in_the_library"
            source={fwvToniTipton.zypeIdSource}
          />
        </div>
        <div className={styles.toniTiptonTextBlock}>
          <TwoColumnText {...tctToniTipton} />
        </div>
      </section>

      <section aria-label="Cook's Country table">
        <div className={styles.joinUsBrandHero}>
          <BrandHero
            altText={bhAmericasFavorite.altText}
            cloudinaryIds={bhAmericasFavorite.cloudinaryIds}
          ></BrandHero>
        </div>
        <div
          className={`${styles.setWidthContentBlock} ${styles.explorationFWContentBlock} ${styles.trustOurRecipes}`}
        >
          <div className={styles.bakedFWContentBlock}>
            <FullWidthContentBlock
              header={fwcbBakedInTheOven.header}
              content={fwcbBakedInTheOven.content}
            />
          </div>
          <div className={styles.bakedImageGrid}>
            <BrandImageGrid
              image1={bigBakedInTheOven.fullPortrait}
              image2={bigBakedInTheOven.fullLandscape}
              image3={bigBakedInTheOven.square1}
              image4={bigBakedInTheOven.square2}
            />
          </div>
        </div>
      </section>

      <section
        className={`${styles.setWidthContentBlock} ${styles.teamMemberCarouselItems}`}
        aria-label="Meet the team"
      >
        <TeamMemberCarousel carouselCards={teamMemberCarouselItems} />
      </section>

      <section className={styles.testimonials} aria-label="Testimonials">
        <Testimonials quotes={testimonials} />
      </section>
    </div>
  );
};

export default CCOHomePage;
