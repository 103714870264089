import React from 'react';
import useAppContext from 'lib/hooks/useAppContext';
import BrandedLandingPageLayout from 'routes/branded_landing_page/BrandedLandingPageLayout';
import CCOHomePage from 'routes/cco_homepage/CCOHomePage';

const CCOHomepage = () => {
  const { url } = useAppContext();
  return (
    <BrandedLandingPageLayout
      canonical={url}
      meta={{
        title:
          "Cook's Country | How to Cook | Quick Recipes | TV Show Episodes  | Cook's Country",
        description:
          "The official home of Cook's Country recipes, how-to cooking guides, and cooking videos. Find the best recipes for fried chicken, pies, cakes, slow cookers, quick and easy dinners, and country cooking.",
      }}
    >
      <CCOHomePage />
    </BrandedLandingPageLayout>
  );
};

export default CCOHomepage;
